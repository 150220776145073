<i18n lang="yaml">
ja:
  cancel: 開催中止
  progress: 開催中
  today: 本日開催
  end: 開催終了
  paid: 有料
  free: 無料
en:
  cancel: Canceled
  progress: In progress
  today: Today
  end: Closed
  paid: Paid
  free: Free
</i18n>

<template>
  <HoMyVketCard
    ref="hoStoreAssetCard"
    class="ho-event-card"
    aspect-ratio="16/9"
    :to="event ? `/play/event/${event.id}` : undefined"
    redirect
  >
    <template #top>
      <slot name="orgnaised-event-card-top" />
    </template>
    <template #thumbnail>
      <ClientOnly>
        <HoMyVketSkeletonScreen
          :is-loading-content="isLoading"
          :border-radius="isSp ? '5px' : '10px'"
          :height="getCardHeight()"
        >
          <HaBaseImage class="image" :src="event?.thumbnail || ''" />
          <template v-if="event">
            <div class="tag-container">
              <!-- 開催中止 -->
              <template v-if="event.isCanceled">
                <p class="tag -cancel">{{ i18n.t('cancel') }}</p>
              </template>

              <!-- 開催中 -->
              <template
                v-else-if="isBetweenTargetDates(event.startAt, event.endAt)"
              >
                <p class="tag -progress">{{ i18n.t('progress') }}</p>
              </template>

              <!-- 本日開催 -->
              <template
                v-else-if="
                  isToday(event.startAt) && isBeforeTargetDate(event.startAt)
                "
              >
                <p class="tag -today">{{ i18n.t('today') }}</p>
              </template>

              <!-- 開催終了 -->
              <template v-else-if="isAfterTargetDate(event.endAt)">
                <p class="tag -end">{{ i18n.t('end') }}</p>
              </template>

              <template v-if="event.isPaid">
                <p class="tag -paid">{{ i18n.t('paid') }}</p>
              </template>
              <template v-else>
                <p class="tag -free">{{ i18n.t('free') }}</p>
              </template>
            </div>
          </template>
        </HoMyVketSkeletonScreen>
      </ClientOnly>
    </template>

    <template #body>
      <ClientOnly>
        <HoMyVketSkeletonScreen
          class="date-skeleton-screen"
          :is-loading-content="isLoading"
          border-radius="50px"
        >
          <template v-if="event">
            <HoPlayEventCardDate
              class="date"
              :start-date="event.startAt"
              :end-date="event.endAt"
            />
          </template>
        </HoMyVketSkeletonScreen>
      </ClientOnly>
      <ClientOnly>
        <HoMyVketSkeletonScreen
          class="name-skeleton-screen"
          :is-loading-content="isLoading"
          :border-radius="isSp ? '5px' : '10px'"
        >
          <template v-if="event">
            <HaLink :to="`/play/event/${event.id}`">
              <HoPlayCardTitle hover :number-of-paragraphs="numberOfParagraphs">
                {{ event.name }}
              </HoPlayCardTitle>
            </HaLink>
          </template>
        </HoMyVketSkeletonScreen>
      </ClientOnly>

      <slot name="orgnaised-event-card-body" />
    </template>

    <template v-if="!isLoading && event" #thumbnail-right-bottom>
      <div class="favorite">
        <HoMyVketFavorite
          class="favorite"
          :is-liked="event.liked"
          :count="event.numberOfLikes"
          :threshold="99999"
          @click="clickFavorite"
        />
      </div>
    </template>
  </HoMyVketCard>
</template>

<script setup lang="ts">
import type { DeepReadonly } from 'vue'

// models
import { MyVketEventListElement } from '@/models/myVketEvent'

// modules
import {
  isBetweenTargetDates,
  isToday,
  isAfterTargetDate,
} from '@/utils/date-control'

const i18n = useI18n()
const isSp = breakpoints.smaller('sp')

type Props = {
  event: DeepReadonly<MyVketEventListElement> | null
  isLoading?: boolean
  numberOfParagraphs?: 1 | 2
}
type Emits = {
  (emit: 'clickFavorite', eventId: number, isLiked: boolean): void
}
const props = withDefaults(defineProps<Props>(), {
  numberOfParagraphs: 2,
  isLoading: false,
})

const emit = defineEmits<Emits>()

const hoStoreAssetCard = ref<ComponentPublicInstance | null>(null)

// いいねボタンクリック
const clickFavorite = () => {
  if (!props.event) return
  const isLiked = props.event.liked || false
  emit('clickFavorite', props.event.id, !isLiked)
}

const getCardHeight = (): string => {
  return hoStoreAssetCard.value
    ? `${hoStoreAssetCard.value.$el.getBoundingClientRect().width}px`
    : 'auto'
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.tag-container {
  display: flex;
  position: absolute;
  top: v.space(0.5);
  z-index: v.$zindex-main + 2;

  .tag {
    color: v.$white;
    font-size: 14px;
    padding: v.space(1) v.space(2);

    &.-cancel {
      background: v.$gray-1;
      border-radius: 0 18px 18px 0;
      color: v.$gray-5;
    }

    &.-progress {
      background: v.$red;
      border-radius: 0 18px 18px 0;
    }

    &.-today {
      background: v.$green;
      border-radius: 0 18px 18px 0;
    }

    &.-end {
      background: v.$gray-5;
      border-radius: 0 18px 18px 0;
    }

    &.-free {
      background: v.$orange;
      border-radius: 18px;
      margin: 0 v.space(0.5);
    }

    &.-paid {
      background: v.$pink-1;
      border-radius: 18px;
      margin: 0 v.space(0.5);
    }

    @include m.sp {
      font-size: 10px;
      padding: v.space(0.5) v.space(1);
    }
  }
}

.date {
  @include m.sp {
    margin-top: v.space(1);
  }
}

.date-skeleton-screen {
  height: 27px;
}

.name-skeleton-screen {
  height: 56px;
}
</style>
