<template>
  <div
    class="ho-play-card-title"
    :class="{ '-hover-able': hover }"
    :style="{
      '--line-clamp': numberOfParagraphs,
      height: numberOfParagraphs === 1 ? '25px' : '50px',
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
type Props = {
  hover?: boolean
  numberOfParagraphs: 1 | 2
}
const props = withDefaults(defineProps<Props>(), {
  hover: false,
})

const height = computed(() =>
  props.numberOfParagraphs === 1 ? '25px' : '50px'
)
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-play-card-title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  flex-grow: 1;
  font-weight: bold;
  height: v-bind(height);
  -webkit-line-clamp: v-bind(numberOfParagraphs);
  line-height: 25px;
  margin-bottom: 6px;
  overflow: hidden;
  word-break: break-word;

  @include m.sp() {
    font-size: 12px;
  }

  &.-hover-able {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: v.$yellow;
      transition: 0.3s;
    }
  }
}
</style>
